import { apiUrl } from '../constants';
import axios from 'axios';

var request = axios.create({
    baseURL: apiUrl,
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token'), 'Content-Type': 'application/json' }
})

export default class AdministrarEmpleadosAPI {

    static ObtenerEmpleadoPorCorreo = (correoElectronico) => {
        return request.post(`Empleados/ObtenerEmpleadoPorCorreo`, {CorreoElectronico: correoElectronico});
    }

    static ObtenerMenusEmpleado = (correoElectronico) => {
        return request.post(`Empleados/ObtenerMenusEmpleado/`, {CorreoElectronico: correoElectronico});
    }

    static GetListaEmpleados = () => {
        return request.get(`Empleados/GetListaEmpleados/`);
    }

    static GetEmpleadosPorTipo = (idTipoEmpleado) => {
        return request.get(`Empleados/GetEmpleadosPorTipo/${idTipoEmpleado}`);
    }

    static GetSupervisoresPorCoord = (idCoordinador) => {
        return request.get(`Empleados/GetSupervisoresPorCoord/${idCoordinador}`);
    }

    static DeleteEmpleado = (empleado) => {
        return request.delete(`Empleados/`, {data: empleado})
    }

    static PostSupervisorCoordinador = (coordinadorSupervisores) => {
        return request.post(`Empleados/SupervisorCoordinador/`, coordinadorSupervisores);
    }

    static DeleteCoordinadorSupervisor = (supervisorCoordinador) => {
        return request.delete(`Empleados/RelSupervisorCoordinador`, {data: supervisorCoordinador})
    }

    static GetGeneros = () => {
        return request.get(`Segmentation/Filter/Gender`);
    }

    static GetSegmentaciones = () => {
        return request.get(`GetSegmentsEmpleados/`);
    }

    static GetTiposEmpleado = () => {
        return request.get(`Empleados/GetTiposEmpleado/`);
    }

    static PutEmpleado = (empleado) => {
        return request.put(`Empleados/`, empleado);
    } 

    static PostEmpleado = (empleado) => {
        return request.post(`Empleados/`, empleado);
    }

    static GetSupervisoresDrive = () => {
        return request.get(`Empleados/GetSupervisoresDrive`);
    }

    static GetSupervisoresDrivePorId = (idEmpleado) => {
        return request.get(`Empleados/GetSupervisoresDrivePorId/${idEmpleado}`);
    }

    static GetSupervisoresDisponibles = () => {
        return request.get(`Empleados/GetSupervisoresDisponibles`);
    }

}