import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import Field from '../util/field/Field';
import AdministrarEmpleadosAPI from '../../services/AdministrarEmpleados';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import ErrorMessages from '../../util/validation-error-messages';
import CaffenioDriveAPI from '../../services/caffenioDrive';
import Loading from '../util/loading/Loading';
import { toast } from 'react-toastify';

// Tipos de empleado existentes
const COORDINADOR = 1
const SUPERVISOR = 2

export const NewCoordinadoresSupervisores = ({ history }) => {

    const [state, setState] = useState({
        empleado: {
            NumEmpleado: "",
            Nombre: "",
            ApellidoP: "",
            ApellidoM: "",
            CorreoElectronico: "",
            idSexo: "",
            idTipoEmpleado: "",
            idSegmento: "",
            SupervisoresAsignados: [],
            DrivesAsignados: [],
            CoordinadorAsignado: null
        },
        listaGeneros: [],
        listaSegmentos: [],
        listaTiposEmpleado: [],
        listaDrives: [],
        listaDrivesFiltrados: [],
        listaCoordinadores: [],
        listaSupervisores: [],
        isLoading: false
    });

    useEffect(() => {
        let stateAux = { ...state }
        let listaGenerosAux = []
        let listaSegmentosAux = []
        let listaTiposEmpleadoAux = []
        let listaDrivesAux = []
        let listaCoordinadoresAux = []
        let listaSupervisoresAux = []

        AdministrarEmpleadosAPI.GetGeneros()
            .then(res => {
                listaGenerosAux = res.data
                if (listaGenerosAux.length > 0) {
                    state.empleado.idSexo = listaGenerosAux[0]
                }
                return AdministrarEmpleadosAPI.GetSegmentaciones()
            })
            .then(res => {
                listaSegmentosAux = res.data.filter(x => x.SegmentId !== 2)
                return AdministrarEmpleadosAPI.GetTiposEmpleado()
            })
            .then(res => {
                listaTiposEmpleadoAux = res.data
                return AdministrarEmpleadosAPI.GetEmpleadosPorTipo(COORDINADOR)
            }).then(res => {
                listaCoordinadoresAux = res.data
                return CaffenioDriveAPI.getCaffenios()
            }).then(res => {
                listaDrivesAux = res.data.filter(x => x.Active === true)
                return AdministrarEmpleadosAPI.GetSupervisoresDisponibles()
            }).then(res => {
                listaSupervisoresAux = res.data
                //Guardar listas aux en el estado
                stateAux.listaGeneros = listaGenerosAux
                stateAux.listaSegmentos = listaSegmentosAux
                stateAux.listaTiposEmpleado = listaTiposEmpleadoAux
                stateAux.listaDrives = listaDrivesAux
                stateAux.listaDrivesFiltrados = listaDrivesAux
                stateAux.listaCoordinadores = listaCoordinadoresAux
                stateAux.listaSupervisores = listaSupervisoresAux
                setState(stateAux)
            })
    }, [])

    const VALIDATION_SCHEMA = Yup.object({
        /* NumEmpleado: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(1, "Número de empleado debe tener 1 caracteres.")
            .max(6, "Ha excedido el límite de 6 caracteres.")
            .required("Campo número de empleado es requerido."), */
        Nombre: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(100, "Ha excedido el límite de 100 caracteres.")
            .required("Campo nombre es requerido."),
        /* ApellidoP: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre Corto debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo apellido paterno es requerido."), */
        /* ApellidoM: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre Corto debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo apellido materno es requerido."), */
        CorreoElectronico: Yup.string()
            .trim()
            .email("El correo electrónico no cumple con el formato correcto")
            .max(60, "Ha excedido el límite de 60 caracteres.")
            .required("Campo correo electrónico es requerido."),
        idSexo: Yup.string().required("Campo género es requerido.").nullable(),
        idSegmento: Yup.string().required("Campo segmento es requerido.").nullable(),
        idTipoEmpleado: Yup.string().required("Campo tipo empleado es requerido.").nullable(),
    });

    return (
        <div>

            {state.isLoading &&
                <Loading
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }

            <div className='card'>
                <div className='card-header'>
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{ position: "absolute", left: 25 }} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i className="fa fa-arrow-left" style={{ cursor: "pointer", color: "#91BA43" }} onClick={() => { history.goBack() }}></i>
                        </span>
                        <div>{"Nuevo empleado"}</div>
                    </div>
                </div>
                <div className='card-content'>
                    <Formik
                        initialValues={state.empleado}
                        validationSchema={VALIDATION_SCHEMA}
                        onSubmit={values => {
                            if (values.idTipoEmpleado.idTipoEmpleado === SUPERVISOR
                                && values.DrivesAsignados.length <= 0) {
                                toast.error("Debe asignar por lo menos un drive")
                            } else {
                                setState({ ...state, isLoading: true })
                                let clienteEnviado = { ...values }
                                clienteEnviado.idSegmento = values.idSegmento.SegmentId
                                clienteEnviado.idSexo = values.idSexo.IdGender
                                clienteEnviado.idTipoEmpleado = values.idTipoEmpleado.idTipoEmpleado
                                AdministrarEmpleadosAPI.PostEmpleado(clienteEnviado)
                                    .then(res => {
                                        toast.success("Empleado creado correctamente")
                                        history.goBack()
                                        setState({ ...state, isLoading: false })
                                    }).catch(res => {
                                        toast.error(res.response.data.Message)
                                        setState({ ...state, isLoading: false })
                                    })
                            }
                        }}
                    >
                        {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <h2>Información del empleado</h2>
                                <br />
                                <div>
                                    <Field
                                        label="Número de empleado"
                                        hasError={!!errors.NumEmpleado || !!touched.NumEmpleado}
                                        msgError={errors.NumEmpleado || touched.NumEmpleado ? errors.NumEmpleado : ""}
                                        isHorizontal
                                    >
                                        <input
                                            name={"NumEmpleado"}
                                            value={values.NumEmpleado}
                                            onChange={handleChange}
                                            className={`input`}
                                            placeholder={"Ingrese número del empleado"}
                                        />
                                    </Field>

                                    <br />

                                    <Field
                                        label="Nombre"
                                        hasError={!!errors.Nombre || !!touched.Nombre}
                                        msgError={errors.Nombre || touched.Nombre ? errors.Nombre : ""}
                                        isHorizontal
                                    >
                                        <input
                                            name={"Nombre"}
                                            value={values.Nombre}
                                            onChange={handleChange}
                                            className={`input`}
                                            placeholder={"Ingrese nombre del empleado"}
                                        />
                                    </Field>

                                    <br />

                                    <Field
                                        label="Apellido Paterno"
                                        hasError={!!errors.ApellidoP || !!touched.ApellidoP}
                                        msgError={errors.ApellidoP || touched.ApellidoP ? errors.ApellidoP : ""}
                                        isHorizontal
                                    >
                                        <input
                                            name={"ApellidoP"}
                                            value={values.ApellidoP}
                                            onChange={handleChange}
                                            className={`input`}
                                            placeholder={"Ingrese apellido paterno del empleado"}
                                        />
                                    </Field>

                                    <br />

                                    <Field
                                        label="Apellido Materno"
                                        hasError={!!errors.ApellidoM || !!touched.ApellidoM}
                                        msgError={errors.ApellidoM || touched.ApellidoM ? errors.ApellidoM : ""}
                                        isHorizontal
                                    >
                                        <input
                                            name={"ApellidoM"}
                                            value={values.ApellidoM}
                                            onChange={handleChange}
                                            className={`input`}
                                            placeholder={"Ingrese apellido paterno del empleado"}
                                        />
                                    </Field>

                                    <br />

                                    <Field
                                        label="Correo Electrónico"
                                        hasError={!!errors.CorreoElectronico || !!touched.CorreoElectronico}
                                        msgError={errors.CorreoElectronico || touched.CorreoElectronico ? errors.CorreoElectronico : ""}
                                        isHorizontal
                                    >
                                        <input
                                            name={"CorreoElectronico"}
                                            value={values.CorreoElectronico}
                                            onChange={handleChange}
                                            className={`input`}
                                            placeholder={"Ingrese correo electrónico del empleado"}
                                        />
                                    </Field>

                                </div>

                                <br />

                                <Field
                                    label="Género"
                                    hasError={!!errors.idSexo || !!touched.idSexo}
                                    msgError={errors.idSexo || touched.idSexo ? errors.idSexo : ""}
                                    isHorizontal
                                >
                                    <div className="control" style={{ width: "100%" }}>
                                        <Select
                                            value={values.idSexo}
                                            placeholder={"Seleccionar..."}
                                            noOptionsMessage={() => "No hay opciones"}
                                            onChange={val => {
                                                setFieldValue("idSexo", val)
                                            }}
                                            options={state.listaGeneros}
                                            getOptionLabel={(genero) => genero.Name}
                                            getOptionValue={(genero) => genero.IdGender}
                                        />
                                    </div>
                                </Field>

                                <br />
                                <Field
                                    label="Puesto"
                                    hasError={!!errors.idTipoEmpleado || !!touched.idTipoEmpleado}
                                    msgError={errors.idTipoEmpleado || touched.idTipoEmpleado ? errors.idTipoEmpleado : ""}
                                    isHorizontal
                                >
                                    <div className="control" style={{ width: "100%" }}>
                                        <Select
                                            placeholder={"Seleccionar..."}
                                            noOptionsMessage={() => "No hay opciones"}
                                            options={state.listaTiposEmpleado}
                                            onChange={val => {
                                                setFieldValue("idTipoEmpleado", val)
                                                setFieldValue("CoordinadorAsignado", null)
                                                setFieldValue("DrivesAsignados", [])
                                                setFieldValue("SupervisoresAsignados", [])
                                            }}
                                            getOptionLabel={(tipoEmpleado) => tipoEmpleado.Descripcion}
                                            getOptionValue={(tipoEmpleado) => tipoEmpleado.idTipoEmpleado}
                                        />
                                    </div>
                                </Field>

                                {values.idTipoEmpleado.idTipoEmpleado === SUPERVISOR &&
                                    <div>
                                        <br />
                                        <Field
                                            label="Coordinador asignado"
                                            isHorizontal
                                        >
                                            <div className="control" style={{ width: "100%" }}>
                                                <Select
                                                    menuPlacement="auto"
                                                    placeholder={"Seleccionar..."}
                                                    noOptionsMessage={() => "No hay opciones"}
                                                    options={state.listaCoordinadores}
                                                    onChange={val => {
                                                        setFieldValue("CoordinadorAsignado", val)
                                                    }}
                                                    getOptionLabel={(coordinador) => coordinador.NombreCompleto}
                                                    getOptionValue={(coordinador) => coordinador.idEmpleado}
                                                />
                                            </div>
                                        </Field>
                                    </div>
                                }

                                {values.idTipoEmpleado.idTipoEmpleado &&
                                    <div>
                                        <hr />
                                        <h2>Configuración de empleado</h2>
                                        <br />
                                        <Field
                                            label="Segmentación"
                                            hasError={!!errors.idSegmento || !!touched.idSegmento}
                                            msgError={errors.idSegmento || touched.idSegmento ? errors.idSegmento : ""}
                                            isHorizontal
                                        >
                                            <div className="control" style={{ width: "100%" }}>
                                                <Select
                                                    menuPlacement="auto"
                                                    placeholder={"Seleccionar..."}
                                                    noOptionsMessage={() => "No hay opciones"}
                                                    options={state.listaSegmentos}
                                                    onChange={val => {
                                                        setFieldValue("idSegmento", val)
                                                        setFieldValue("DrivesAsignados", [])
                                                        let filtrarDrives = state.listaDrives.map(x => {
                                                            if(x.idSegmento === 2 && val.SegmentId === 1){
                                                                x.idSegmento = 1
                                                            }
                                                            return x
                                                        })
                                                        filtrarDrives = filtrarDrives.filter(x => x.idSegmento === val.SegmentId)
                                                        setState({ ...state, listaDrivesFiltrados: filtrarDrives })
                                                    }}
                                                    getOptionLabel={(segmento) => {
                                                        switch (segmento.Name) {
                                                            case "Andatti drive":
                                                                return "Andatti"
                                                            case "Drive":
                                                                return "Caffenio"
                                                            case "momento espresso":
                                                                return "Momento Espresso"
                                                            default:
                                                                return segmento.Name
                                                        }
                                                    }}
                                                    getOptionValue={(objSegment) => objSegment.SegmentId}
                                                />
                                            </div>
                                        </Field>
                                    </div>
                                }

                                {values.idTipoEmpleado.idTipoEmpleado === SUPERVISOR &&
                                    <div>

                                        <br />

                                        <Field
                                            label="Drives asignados"
                                            isHorizontal
                                        >
                                            <div className="control" style={{ width: "100%" }}>
                                                <Select
                                                    menuPlacement="auto"
                                                    value={values.DrivesAsignados}
                                                    isMulti
                                                    placeholder={"Seleccionar..."}
                                                    noOptionsMessage={() => "No hay opciones"}
                                                    options={state.listaDrivesFiltrados}
                                                    onChange={val => {
                                                        setFieldValue("DrivesAsignados", val)
                                                    }}
                                                    getOptionLabel={(drive) => drive.Name}
                                                    getOptionValue={(drive) => drive.idCaffenio}
                                                />
                                            </div>
                                        </Field>

                                    </div>
                                }

                                {values.idTipoEmpleado.idTipoEmpleado === COORDINADOR &&
                                    <div>
                                        <br />

                                        <Field
                                            label="Supervisores asignados"
                                            isHorizontal
                                        >
                                            <div className="control" style={{ width: "100%" }}>
                                                <Select
                                                    menuPlacement="auto"
                                                    isMulti
                                                    placeholder={"Seleccionar..."}
                                                    noOptionsMessage={() => "No hay supervisores disponibles"}
                                                    options={state.listaSupervisores}
                                                    onChange={val => {
                                                        setFieldValue("SupervisoresAsignados", val)
                                                    }}
                                                    getOptionLabel={(supervisor) => supervisor.NombreCompleto}
                                                    getOptionValue={(supervisor) => supervisor.idEmpleado}
                                                />
                                            </div>
                                        </Field>
                                    </div>
                                }

                                <div className="has-text-right buttons-modal">
                                    <div className="column">
                                        <button type="submit" className="button is-success">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}
