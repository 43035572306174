import React, { useEffect, useState } from "react";

import Modal from "../util/modal/Modal";
import Loader from "../util/loading/Loading";
import { toast } from "react-toastify";

import AdministrarEmpleadosAPI from "../../services/AdministrarEmpleados";
import NoItems from "../util/noitems/NoItems";
import Select from 'react-select';

// Tipos de empleado existentes
const COORDINADOR = 1
const SUPERVISOR = 2

export const ModalListaSupervisors = ({ coordinadorSeleccionado, onClose }) => {

  const [state, setState] = useState({
    coordinador: coordinadorSeleccionado,
    supervisoresAsignados: [],
    listaSupervisores: [],
    supervisoresSelected: [],
    isLoading: true,
  });

  useEffect(() => {

    let auxSupervisoresAsignados = []
    let auxlistaSupervisores = []

    AdministrarEmpleadosAPI.GetSupervisoresPorCoord(coordinadorSeleccionado.idEmpleado)
      .then(res => {
        auxSupervisoresAsignados = res.data
        return AdministrarEmpleadosAPI.GetSupervisoresDisponibles()
      })
      .then(res => {
        auxlistaSupervisores = res.data
        setState({
          ...state,
          supervisoresAsignados: auxSupervisoresAsignados,
          listaSupervisores: auxlistaSupervisores,
          isLoading: false
        })
      })
  }, []);

  const handleGuardarSupervisores = () => {
    if (state.supervisoresSelected.length === 0) {
      toast.error("Debe seleccionar por lo menos un supervisor")
    } else {
      setState({ ...state, isLoading: true })
      let stateAux = { ...state }
      let coordinadorSupervisores = []
      state.supervisoresSelected.forEach(element => {
        coordinadorSupervisores.push({
          idEmpleadoCoord: state.coordinador.idEmpleado,
          idEmpleadoSup: element.idEmpleado
        })
      });
      AdministrarEmpleadosAPI.PostSupervisorCoordinador(coordinadorSupervisores)
        .then(res => {
          return AdministrarEmpleadosAPI.GetSupervisoresPorCoord(coordinadorSeleccionado.idEmpleado)
        }).then(res => {
          stateAux.isLoading = false
          stateAux.supervisoresAsignados = res.data
          stateAux.supervisoresSelected = []
          stateAux.listaSupervisores = stateAux.listaSupervisores.filter(sup =>
            res.data.every(supAux => supAux.idEmpleado !== sup.idEmpleado))
          setState(stateAux)
          toast.success("Gerentes asignados correctamente")
        })
    }
  }

  const handleEliminarRelSupervisor = (supervisor) => {
    setState({ ...state, isLoading: true })
    let stateAux = { ...state }
    stateAux.supervisoresAsignados.splice(stateAux.supervisoresAsignados
      .findIndex(e => e.idEmpleado === supervisor.idEmpleado), 1)
    stateAux.isLoading = false
    stateAux.listaSupervisores = [...stateAux.listaSupervisores, supervisor]
    let supervisorCoordinador = {
      idEmpleadoCoord: state.coordinador.idEmpleado,
      idEmpleadoSup: supervisor.idEmpleado
    }
    AdministrarEmpleadosAPI.DeleteCoordinadorSupervisor(supervisorCoordinador)
      .then(res => {
        setState(stateAux)
        toast.success("Gerente desvinculado del coordinador correctamente")
      })
  }

  const onChangeSupervisor = (supervisores) => {
    setState({ ...state, supervisoresSelected: supervisores })
  }

  return (
    <Modal
      showModal={true}
      modalTitle={coordinadorSeleccionado.NombreCompleto}
      width="55%"
      cancel={() => onClose()}
    >
      {state.isLoading && (
        <Loader
          isFullscreen={true}
          isLoading={state.isLoading}
          width="100px"
          height="100px"
        />
      )}

      <div className="row">
        <label className="label">Asignar nuevos gerentes</label>
        <br />
        {/* Multi Select para seleccionar cuales supervisores se asignaran al coordinador */}
        <div className='columns is-multiline'>
          <div className='column is-9'>
            <Select
              isMulti
              value={state.supervisoresSelected}
              placeholder={"Seleccionar..."}
              noOptionsMessage={() => "No hay supervisores disponibles"}
              options={state.listaSupervisores}
              getOptionLabel={(supervisor) => supervisor.NombreCompleto}
              getOptionValue={(supervisor) => supervisor.idEmpleado}
              onChange={(item) => onChangeSupervisor(item)}
            />
          </div>
          <div className='column is-3'>
            <button onClick={handleGuardarSupervisores} className="button is-success">
              Asignar
            </button>
          </div>
        </div>
      </div>

      {/* Lista de supervisores que tiene asignado el coordinador */}
      <hr />
      <label className="label">Lista de gerentes asignados</label>
      <br />
      <div className="card">
        <div className="card-content">
          {state.supervisoresAsignados.length !== 0
            ?
            <table className='table is-fullwidth'>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Drives</th>
                  <th>Municipio</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.supervisoresAsignados.map(supervisor =>
                    <tr key={supervisor.idEmpleado}>
                      <td>{supervisor.NombreCompleto}</td>
                      <td style={{ 'width': 250 }}>
                        <Select
                          menuPlacement="auto"
                          value={null}
                          isClearable={false}
                          options={supervisor.listaDrives}
                          placeholder={"Ver Drives asignados"}
                          getOptionLabel={(drive) => drive.Drive}
                          getOptionValue={(drive) => drive.idCaffenio}
                        />
                      </td>
                      <td>
                        {
                          supervisor.listaDrives.filter((value, index, array) =>
                            array.findIndex(value2 => (value2.idMunicipio === value.idMunicipio)) === index)
                            .map((value, index, array) => {
                              if (array.length - 1 !== index) {
                                return `${value.Municipio}, `
                              } else {
                                return value.Municipio
                              }
                            })
                        }
                      </td>
                      <td>
                        {
                          supervisor.listaDrives.filter((value, index, array) =>
                            array.findIndex(value2 => (value2.idEstado === value.idEstado)) === index)
                            .map((value, index, array) => {
                              if (array.length - 1 !== index) {
                                return `${value.Estado}, `
                              } else {
                                return value.Estado
                              }
                            })
                        }
                      </td>
                      <td>
                        {
                          <button className="button tableAction is-danger tooltip" data-tooltip="Eliminar empleado"
                            onClick={() => handleEliminarRelSupervisor(supervisor)}
                          >
                            <span className="icon">
                              <i className="fa fa-trash" />
                            </span>
                          </button>
                        }
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            : <NoItems itemName="gerentes asignados" />}
        </div>
      </div>
    </Modal>
  );
};
