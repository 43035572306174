import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import Modal from '../../util/modal/Modal';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import Pagination from '../../util/pagination/Pagination';
import Loading from '../../util/loading/Loading';
import InventoryItemsAPI from '../../../services/InventoryItem';
import { toast } from 'react-toastify';
import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import Select from 'react-select';
import CaffenioAPI from '../../../services/Warehouse';//Obtenemos los Drives
import Field from '../../util/field/Field';
import ErrorMessages from '../../../util/validation-error-messages';
import TextField from '../../util/textfield/TextField';
import lodash from 'lodash';
import AdministrarEmpleadosAPI from '../../../services/AdministrarEmpleados';


class Toppings extends Component {
    inventoryItems = [];

    constructor(props){
        super(props);
        this.state = {
            inventoryItems: [],
            pageInventoryItems: [],
            filterText: "",
            inventoryItem: null,
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false,
            esAdmin: false,
            usuarioCaffenios: 0,
            Caffenios: [],
            selectedOption: [],
            selectedOptionC: [],
            selectedOptionD: [],
            OptionSelect: [],
            OptionSelectCity: [],
            OptionSelectDrive: [],
            drivesArr: [],
            checkAll: false,
            updateMasive: false,
            confirmModalUpdatePrice: false,
            priceMassive: null,
            buttonSelectAll: false,
            selectedToppingsObjList: [],
            //modal para actualizar precio individualmente
            modalUpdatePrice:false,
            modalSeeToppingsExclude:false,
            LstDrivesExcluded:[],
            removeAllExcludedToppings:false
        }
    }

    componentWillMount(){
        //Se prefiltran los menús con base a los que tenga el usuario (si es que tiene)
        //del módulo de Coordinadores y Supervisores (Gerentes)
        let usuarioCaffenios = []
        let esAdminAux = false 
        const jsonToken = this.parseJwt(localStorage.getItem('token'))
        
        AdministrarEmpleadosAPI.ObtenerEmpleadoPorCorreo(jsonToken.email)
        .then(res => {
            if(jsonToken.role.includes('Especialista de producto') || jsonToken.role.includes('Administrador') || res.data.idTipoEmpleado === 1){
                esAdminAux = true
                this.setState({ esAdmin: esAdminAux })
            }
        }).catch(err => {
            console.log(err)
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
        
        if(!esAdminAux){
            AdministrarEmpleadosAPI.ObtenerMenusEmpleado(jsonToken.email)
            .then(res => {
                usuarioCaffenios = res.data
                this.setState({usuarioCaffenios: usuarioCaffenios, esAdmin: esAdminAux})
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            })
        }

        /**
         * Obtenemos los drives para hacer el filtrado
         */
        this.setState({isLoading: true, esAdmin: esAdminAux})
        CaffenioAPI.getCaffenios().then(response => {
            this.setState({
                Caffenios:response.data,
                optionState:this.select_state(response.data),
                optionCity:this.select_city(response.data),
                optionDrive:this.select_Drive(response.data)
            });

            return CaffenioAPI.getAll();

        }).then(response => {

            this.setState({isLoading:false,drivesArr:response.data});

        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });

        this.setState({isLoading: true});
        /**
         * Obtenemos todos los toppings
         */
        InventoryItemsAPI.getAllToppings().then(response => {
            let resultadoFiltrado = []
            if(!esAdminAux){
                resultadoFiltrado = response.data.filter(x => usuarioCaffenios.some(y => y.idCaffenio === x.idDrive))
            }else{
                resultadoFiltrado = response.data
            }
            
            this.setState({inventoryItems: resultadoFiltrado, isLoading: false});
            this.inventoryItems = resultadoFiltrado;
            this.inventoryItems.map(item =>{
                item.statusCheck = false;
            })
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }
    /**
     * Método encargado de recibir el texto en el input de búsqueda
     * y filtrar los elementos en la tabla
     */
    handleSearchInput = (text) => {
        let state = {...this.state};
        state.filterText = text;
        this.setState(state, () => this.FilterProcess());
    }

    parseJwt = token => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    };

    remove = () => {
        this.setState({isLoading: true});

        // Llamar a la funcion para eliminar un topping de la lista de topppings
        InventoryItemsAPI.removeToppingFromPriceList(this.state.inventoryItem.InventoryItemId, this.state.inventoryItem.idListaPrecio).then(response => {

            let state = {...this.state};

            let inventoryItems = this.inventoryItems.filter(inventoryItem => {
                if(inventoryItem.InventoryItemId !== state.inventoryItem.InventoryItemId
                ||(inventoryItem.InventoryItemId === state.inventoryItem.InventoryItemId &&inventoryItem.idListaPrecio !==  state.inventoryItem.idListaPrecio)){
                    return inventoryItem;
                } }
            );
            state.inventoryItems = state.inventoryItems.filter(inventoryItem=>{
                if(inventoryItem.InventoryItemId !== state.inventoryItem.InventoryItemId
                || (inventoryItem.InventoryItemId === state.inventoryItem.InventoryItemId && inventoryItem.idListaPrecio !==  state.inventoryItem.idListaPrecio)){
                        return inventoryItem;
                }

            });
            this.inventoryItems = inventoryItems;

            state.isLoading = false;
            state.removeModal = false;
            state.inventoryItem = null;
            this.setState(state, () =>{ toast.success("Topping eliminado")});
        }).catch(err => {
            if(err){
                this.setState({isLoading: false, inventoryItem: null, removeModal: false});
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            }
        });
    }
    /**
     * Funcion para filtrar los articulos inventarios mediante
     * Estados, Ciudad y Drive
     */
    FilterProcess = () => {
        let state = {...this.state}
        let drivesArr = [...this.inventoryItems];
        let driveIds;

        //Obtenemos los id de los drives de la variable selectedOptionD
        if(state["selectedOptionD"].length===0){
            driveIds = state["optionDrive"].map(obj => {
                return obj.value;
            })
        } else{
            driveIds = state["selectedOptionD"].map(obj => {
                return obj.value;
            })
        }

        //verificamos si es de una busqueda mediante nombre, nombre corto, lista de precio o PT
        if(state.filterText.trim() !== ''){
            drivesArr = drivesArr.filter(obj => {
                return (obj.Name.toLowerCase().includes(state.filterText.trim().toLowerCase()) ||
                        obj.CodeProvider.toLowerCase().includes(state.filterText.trim().toLowerCase()) ||
                        obj.ShortName.toLowerCase().includes(state.filterText.trim().toLowerCase()) ||
                        obj.NombreListaPrecio.toLowerCase().includes(state.filterText.trim().toLowerCase()
                ))

            });
        }
        //Hacemos el filtrado mediante drives
        drivesArr = drivesArr.filter((obj2)=>{
            if(driveIds.filter((item)=>{
                return obj2.Drives.findIndex(idDrive => idDrive === item) !== -1
            }).length > 0){ return obj2 }
        })
        state.inventoryItems = drivesArr;

        state.inventoryItems.map(item=>{
            if(item.statusCheck){
                item.statusCheck = false;
            }
        });
        state.buttonSelectAll = false;
        state.selectedToppingsObjList = []

        this.setState(state);
    }
    /**
     * Obtiene las ciudades que iran en el select multi de Estado
     * @param {Object}  Caffenios - Caffenios contiene toda la informacion de los drives
     */
    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones
        var newArray = [];
        let OptionSelect = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelect.push({value:item.StateId,label:item.State});
        });

        for(var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }

        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }
    /**
     * Obtiene las ciudades que iran en el select multi de ciudad
     * @param {Object}  Caffenios - Caffenios contiene toda la informacion de los drives
     */
    select_city(Caffenios) { // Llenar el combo 'Ciudades' con todas las opciones
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectCity.push({value:item.Municipalityid,label:item.Municipality});
        });

        for(var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }

        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    /**
     * Obtiene los drives que iran en el select multi de Drive
     * @param {Object}  Caffenios - Caffenios - contiene toda la informacion de los drives
     */
    select_Drive(Caffenios) { // Llenar el combo 'Drives' con todas las opciones
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectDrive.push({value:item.idCaffenio,label:item.NombreNuevo});
        });

        for(var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }

        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    /**
     * Nos elimina la informacion de los drives que hay duplicados
     * @param {Object}  arr - contiene toda la informacion de los drives
     * @param {string}  prop - Es una llave de un objeto
     */
    eliminarObjDuplicados = (arr,prop) => {
        var nuevoArray = [];
        var lookup  = {};

        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }

        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }

        return nuevoArray;
    }

    /**
     * Actualiza el objeto donde tenemos todas las ciudades, estados y drives seleccionadas
     * @param {Object}  obj - contiene toda la informacion del select del estado
     */
    changeSelectEstado = (obj) => {
        let state = {...this.state}
        state["selectedOption"] = obj;
        let idEstado = obj.map(estado => {return estado.value});
        let objCaffenios = [...state.Caffenios];
        let listaCiudades = [];
        let listaDrives = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item =>{
            for(var i=0; i<idEstado.length; i++){
                if(item.StateId===idEstado[i]){
                    listaCiudades.push({"value":item.Municipalityid,"label":item.Municipality});
                }
            }
        })

        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades,"value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => {return ciudad.value});

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                }
            }
        })

        //Se limpian los combos 'Ciudades' y 'Drives'
        state["selectedOptionC"] = [];
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if(obj.length===0){
            state["optionCity"] = this.select_city(state.Caffenios);
            state["optionDrive"] = this.select_Drive(state.Caffenios);

        } else{
            state["optionCity"] = eliminarDuplicados;
            state["optionDrive"] = listaDrives;
        }

        this.setState(state, () => this.FilterProcess());
    }
    /**
     * Actualiza el objeto donde tenemos todas las ciudades y drives seleccionadas
     * @param {Object}  obj - Contiene toda la informacion del select ciudad
     */
    changeSelectCiudad = (obj) =>{
        let state = {...this.state}
        let idCiudad = obj.map(ciudad => {return ciudad.value});
        let objCaffenios = [...state.Caffenios];
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                }
            }
        })

        //Se limpia el combo 'Drives'
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if(obj.length>0){
            state["selectedOptionC"] = obj;
            state["optionDrive"] = listaDrives;
            this.setState(state, () => this.FilterProcess());
        } else{
            this.changeSelectEstado(state["selectedOption"]);
        }
    }

    /**
     * Actualiza el objeto donde tenemos todos los drives seleccionados
     * @param {Object}  obj - Contiene toda la informacion del select drive
     */
    changeSelectDrive = (obj) =>{
        let state = {...this.state};
        let Caffenios = [...state.Caffenios];

        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if(obj.length>0){
            state["selectedOptionD"] = obj;
            //state["optionDrive"] = obj;
            this.setState(state, () => this.FilterProcess());
        } else if(state["selectedOptionC"].length===0){
            this.changeSelectEstado(state["selectedOption"]);
        } else if(state["selectedOptionC"].length===0 && state["selectedOption"].length===0){
            this.select_Drive(Caffenios);
        } else{
            this.changeSelectCiudad(state["selectedOptionC"]);
        }
    }

    /*
    * Funcion seleccionar o des seleccionar todos las recetas
    * de la pagina actual.
    */
   selectAllCheckBoxPage = () => {
        let _checkAll = !this.state.checkAll
        let _selectedToppingsObjList = [...this.state.selectedToppingsObjList]
        let _pageToppings = this.state.pageInventoryItems
        console.log(_selectedToppingsObjList)
        _pageToppings.forEach(topping => {
            let indexTopping = _selectedToppingsObjList.findIndex(item => item === String(topping.InventoryItemId) + "-" + String(topping.idListaPrecio) + "-" + String(topping.idDrive))
            topping.statusCheck = _checkAll
            if(_checkAll){
                if(indexTopping === -1) _selectedToppingsObjList.push(String(topping.InventoryItemId) + "-" + String(topping.idListaPrecio) + "-" + String(topping.idDrive))
            }else{
                if(indexTopping !== -1) _selectedToppingsObjList.splice(indexTopping, 1)
            }
        })

        this.setState({
            selectedToppingsObjList: _selectedToppingsObjList,
            updateMasive: _selectedToppingsObjList.length > 0,
            checkAll: _checkAll,
            buttonSelectAll: _selectedToppingsObjList.length === this.state.inventoryItems.length
        })
    }
    /*
    * Funcion para saber si hay que mostrar
    * un switch y boton para aplicar cambios a las
    * recetas que hemos marcado.
    */
    showView = (index) => {
        // Utilizar indice compuesto InventoryItemId + "-" + idListaPrecio
        let _selectedToppingsObjList = [...this.state.selectedToppingsObjList];
        let indexTopping = this.state.selectedToppingsObjList.findIndex(id => id === String(this.state.pageInventoryItems[index].InventoryItemId) + "-" + String(this.state.pageInventoryItems[index].idListaPrecio))
        if (indexTopping === -1) {
            _selectedToppingsObjList.push(String(this.state.pageInventoryItems[index].InventoryItemId) + "-" + String(this.state.pageInventoryItems[index].idListaPrecio) + "-" + String(this.state.pageInventoryItems[index].idDrive))
        } else {
            _selectedToppingsObjList.splice(indexTopping, 1)
        }

        let counter = 0; // variable para comparar los elementos seleccionados en la pagina con la cantidad de elementos por pagina
        this.state.pageInventoryItems.map(obj => {
            if (obj.statusCheck) counter++
        })

        this.setState({
            selectedToppingsObjList: _selectedToppingsObjList,
            updateMasive: _selectedToppingsObjList.length > 0,
            buttonSelectAll: _selectedToppingsObjList.length === this.state.inventoryItems.length,
            checkAll: counter === this.state.pageInventoryItems.length
        })
    }
    /*
    * Funcion que selecciona o des selecciona todas las
    * recetas de todas las paginas.
    */
    selectAllCheckBox = () => {
        let _selectedToppingsObjList = [...this.state.selectedToppingsObjList]
        let _toppings = this.state.inventoryItems
        let _buttonSelectAll = !this.state.buttonSelectAll;
        console.log(_toppings)
        _toppings.forEach(topping => {
            // Ver si el elemento ya esta en la lista de seleccioandos
            let indexTopping = _selectedToppingsObjList.findIndex(item => item === String(topping.InventoryItemId) + "-" + String(topping.idListaPrecio) + "-" + String(topping.idDrive))

            // Meter el elemento a la lista si no estaba previamente
            if (_buttonSelectAll && indexTopping === -1) {
                _selectedToppingsObjList.push(String(topping.InventoryItemId) + "-" + String(topping.idListaPrecio) + "-" + String(topping.idDrive))
            // Sacar al elemento de la lista si ya estaba
            } else if (!_buttonSelectAll && indexTopping !== -1) {
                _selectedToppingsObjList.splice(indexTopping, 1)
            }
            topping.statusCheck = _buttonSelectAll;
        })

        let _checkAll       = _buttonSelectAll;

        this.setState({
            selectedToppingsObjList: _selectedToppingsObjList,
            updateMasive: _selectedToppingsObjList.length > 0,
            checkAll: _checkAll,
            buttonSelectAll: _buttonSelectAll
        })
    }

    /**
     * Funcion para actualizar masivamente el precio
     * de las recetas seleccionadas.
     */
    updateMasive = () => {
        this.setState({isLoading: true, confirmModalUpdatePrice:false});
        let toppings  = [];

        // Guardamos todas las recestas seleccionadas
        this.state.selectedToppingsObjList.map(obj => {
            let indiceCompuesto = obj.split("-");
            let inventoryItemId = indiceCompuesto[0];
            let priceListId = indiceCompuesto[1];
            toppings.push({
                ToppingPrice: this.state.priceMassive,
                PriceListId: priceListId,
                InventoryItemId: inventoryItemId
            })
        })

        //Mandamos las recetas al endpoint
        InventoryItemsAPI.setAllPriceToppings(toppings).then(response => {
            let state = {...this.state};
            this.updateTable(this.state.priceMassive);
            //state.showModal = false;
            state.isLoading = false;
            state.priceMassive = 0;
            state.updateMasive = false;
            state.checkAll = false
            if(state.buttonSelectAll) {
                state.buttonSelectAll = false;
            }
            state.selectedToppingsObjList = [];
            this.setState(state, () => {
                toast.success("Precios actualizados correctamente");
            });
        }).catch(err => {
            let state = {...this.state};
            state.showModal = false;
            state.isLoading = false;
            this.setState(state);
            toast.error("Ocurrió un error al actualizar los precios");
        });

    }

    ExcludeSelectedToppings = () => {
        // this.setState({isLoading: true, confirmModalUpdatePrice:false});
        let toppings  = [];

        // Guardamos todas las recestas seleccionadas
        this.state.selectedToppingsObjList.map(obj => {
            let indiceCompuesto = obj.split("-");
            let inventoryItemId = indiceCompuesto[0];
            let priceListId = indiceCompuesto[1];
            let DriveId = indiceCompuesto[2] != null ? Number.parseInt(indiceCompuesto[2]) : null;
            toppings.push({
                ToppingPrice: 0,
                PriceListId: priceListId,
                InventoryItemId: inventoryItemId,
                idCaffenio: DriveId
            })
        })
        //Mandamos las recetas al endpoint
        this.setState({isLoading:true})
        InventoryItemsAPI.ExcludeToppings(toppings).then(response => {
            let state = {...this.state};
            state.isLoading = false;
            state.updateMasive = false;
            state.checkAll = false
            state.confirmModalUpdateExcludeToppings = false
            if(state.buttonSelectAll) {
                state.buttonSelectAll = false;
            }
            state.selectedToppingsObjList = [];
            state.filterText = ''
            state.selectedOption = []
            state.selectedOptionC = []
            state.selectedOptionD = []
            this.setState(state, () => {
                toast.success("Toppings excluidos correctamente");
            });
            return InventoryItemsAPI.getAllToppings()
        }).then(response => {
            let resultadoFiltrado = []
            if (!this.state.esAdmin) {
                //Método que vuelve a cargar todos los toppings una vez que se excluyeron X toppings
                resultadoFiltrado = response.data.filter(x => this.state.usuarioCaffenios.some(y => y.idCaffenio === x.idDrive))
            } else {
                resultadoFiltrado = response.data
            }
            this.setState({ inventoryItems: resultadoFiltrado, isLoading: false });
            this.inventoryItems = resultadoFiltrado;
            this.inventoryItems.map(item =>{
                item.statusCheck = false;
            })
        })
        .catch(err => {
            let state = {...this.state};
            state.isLoading = false;
            state.confirmModalUpdateExcludeToppings = false
            this.setState(state);
            toast.error("Ocurrió un error al excluir toppings los precios");
        });

    }

    /**
     * Función que actualiza el precio de un toping para una lista de precio
     */
    updateIndividual = () => {
        if(!this.state.priceMassive || this.state.priceMassive === 0){
            toast.dismiss()
            toast.error("El precio es un campo requerido.")
            return;
        }
        this.setState({isLoading:true}, ()=>{
            let toppings  = [];
            toppings.push({
                ToppingPrice: this.state.priceMassive,
                PriceListId: this.state.inventoryItem.idListaPrecio,
                InventoryItemId: this.state.inventoryItem.InventoryItemId
            });

            InventoryItemsAPI.setAllPriceToppings(toppings).then(response => {
                let state = {...this.state};

                this.inventoryItems.map(item => {
                    if(item.idListaPrecio === state.inventoryItem.idListaPrecio
                        && item.InventoryItemId === state.inventoryItem.InventoryItemId){
                            item.PrecioVenta = state.priceMassive
                    }
                    return item;
                });
                state.inventoryItems.map(item => {
                    if(item.idListaPrecio === state.inventoryItem.idListaPrecio
                        && item.InventoryItemId === state.inventoryItem.InventoryItemId){
                            item.PrecioVenta = state.priceMassive
                    }
                    return item;
                });
                state.pageInventoryItems.map(item => {
                    if(item.idListaPrecio === state.inventoryItem.idListaPrecio
                        && item.InventoryItemId === state.inventoryItem.InventoryItemId){
                            item.PrecioVenta = state.priceMassive
                    }
                    return item;
                });

                state.isLoading=false;
                state.priceMassive= null;
                state.modalUpdatePrice= false;
                state.inventoryItem = null;
                    this.setState(state, () => {
                        toast.dismiss()
                        toast.success("Precios actualizado correctamente");
                });
            }).catch(err => {
                this.setState({isLoading:false});
                toast.error("Ocurrió un error al actualizar");
            });

        })
    }


    /**
     * Funcion para validar la entrada de datos en el input
     * que modifica todos los precios de las recetas
     * selecionadas.
     */
    handleChange = (e) => {
        let state = {...this.state};
        state.priceMassive = e.target.value;

        if (e.target.value == Math.floor(e.target.value)) {
            if(e.target.value < 0 || e.target.value > 9999999) {
                toast.error("El Precio debe ser mayor a 0 y menor a 9999999");
                return;
            }
        } else {
            if ( !this.isNumericDecimal(e.target.value, 2) ) {
                toast.error("El Precio debe contenedor dos decimales solamente");
                return;
            }else if(e.target.value < 0.00 || e.target.value > 9999999.99) {
                toast.error("El Precio debe ser mayor a 0.00 y menor a 9999999.99");
                return;
            }
        }
        this.setState(state);
    }

    /**
     * Checa si es decimal un numero
     * @param {strin}  val - precio a actualizar
     * @param {int}  decimalPlaces - numero de decimales
     */
    isNumericDecimal = (val, decimalPlaces) => {
        // If the last digit is a . then add a 0 before testing so if they type 25. it will be accepted
        var lastChar = val.substring(val.length - 1);
        if (lastChar == ".") val = val + "0";

        var objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1," + decimalPlaces + "})?|\\.\\d{1," + decimalPlaces + "})\\s*$", "g");
        if (decimalPlaces == -1)
            objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1,25})?|\\.\\d{1,25})\\s*$", "g");

        return objRegExp.test(val);
    };

    /**
     * Actualiza el objeto donde tenemos todos los drives seleccionados
     * @param {Object}  obj - Contiene toda la informacion del select drive
     */
    updateTable = (precioVenta)=>{
        let state = {...this.state};
        this.state.inventoryItems.map(item=>{
            if(item.statusCheck){
                item.statusCheck = false;
                item.PrecioVenta = precioVenta;
            }
        });
        this.setState(state);
    }

    ShowModalToppingsExclude = (model) => {
        this.setState({isLoading:true})
        InventoryItemsAPI.GetAllDrivesExcluded(model.InventoryItemId).then(response => {
            let state = {...this.state};
            state.isLoading = false;
            state.modalSeeToppingsExclude = true
            if (!this.state.esAdmin) {
                //Método para mostrar solos los drives que puede eliminar de la exclución en el modal de excluir
                state.LstDrivesExcluded = response.data.filter(x => this.state.usuarioCaffenios.some(y => y.idCaffenio === x.idCaffenio))
            } else {
                state.LstDrivesExcluded = response.data
            }
            this.setState(state);
        }).catch(err => {
            let state = {...this.state};
            state.isLoading = false;
            this.setState(state);
            toast.error("Ocurrió un error al cargar la información");
        });
        // this.setState({modalSeeToppingsExclude:true, isLoading:true})
    }

    
    RemoveAllExcludeToppings = (lstDrives, removeAll) => {
        // this.setState({isLoading: true, confirmModalUpdatePrice:false});
        let toppings  = [];
        this.setState({isLoading:true, removeAllExcludedToppings: removeAll})
        InventoryItemsAPI.RemoveExcludeToppings(lstDrives).then(response => {
            let state = {...this.state};
            // state.isLoading = false;
            state.modalSeeToppingsExclude = false;
            this.setState(state, () => {
                toast.success("Se guardó correctamente");
            });
            return InventoryItemsAPI.getAllToppings()
        }).then(response => {
            let resultadoFiltrado = []
            if (!this.state.esAdmin) {
                resultadoFiltrado = response.data.filter(x => this.state.usuarioCaffenios.some(y => y.idCaffenio === x.idDrive))
            } else {
                resultadoFiltrado = response.data
            }
            let state = {...this.state};
            state.selectedToppingsObjList = [];
            state.filterText = ''
            state.selectedOption = []
            state.selectedOptionC = []
            state.selectedOptionD = []
            state.inventoryItem = resultadoFiltrado
            state.isLoading = false

            state.LstDrivesExcluded.forEach((item)=>{
                let objInventory = state.inventoryItems.find((i)=> i.InventoryItemId === item.idArticuloInventario && i.idDrive === item.idCaffenio);
                objInventory.ToppingExcluido = null;
            })

            state.LstDrivesExcluded = [];

            this.setState(state);
            this.inventoryItems = resultadoFiltrado;
            this.inventoryItems.map(item =>{
                item.statusCheck = false;
            })
        })
        .catch(err => {
            let state = {...this.state};
            state.isLoading = false;
            this.setState(state);
            toast.error("Ocurrió un error al eliminar la restricción de toppings");
        });

    }

    RemoveSingleExcludeToppings = (lstDrives) => {
        // this.setState({isLoading: true, confirmModalUpdatePrice:false});
        let toppings  = [];
        this.setState({isLoading:true})
        InventoryItemsAPI.RemoveExcludeToppings(lstDrives).then(response => {
            let state = {...this.state};
            let itemRemoved = lstDrives[0]
            let newList = [];
            
            state.LstDrivesExcluded.forEach((item)=>{
                if(item.idArticuloInventario !== itemRemoved.idArticuloInventario){
                    if(item.idCaffenio === itemRemoved.idCaffenio){
                        newList.push(item)
                    }
                }else{
                    if(item.idCaffenio !== itemRemoved.idCaffenio){
                        newList.push(item)
                    }
                }
            })
            state.isLoading = false;
            state.LstDrivesExcluded = newList

            let objInventory = state.inventoryItems.find((i)=> i.InventoryItemId === itemRemoved.idArticuloInventario && i.idDrive === itemRemoved.idCaffenio);
            objInventory.ToppingExcluido = null;

            this.setState(state, () => {
                toast.success("Se guardó correctamente");
            });
        }).catch(err => {
            let state = {...this.state};
            state.isLoading = false;
            this.setState(state);
            toast.error("Ocurrió un error al eliminar la restricción de toppings");
        });

    }

    render() {
        return (
            <div>

                <ModoGuiado
                    ruta="toppings"
                    tipoModoGuiado="receta"
                    anterior="presentaciones"
                    siguiente='recetas'
                    mensaje="Información, agrupación y asignación del producto terminado (PT) o externo  (PE)" />

                    <h1 className="title is-2">Toppings</h1>
                    <div className="card">
                            <div className="card-content">
                            <div className="columns">
                                <div className="column is-3">
                                <label className="label">Búsqueda</label>
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'por Nombre/Nombre Corto/Código PT'}
                                            placeholderText={"Ingrese nombre o nombre corto de topping"}
                                        />
                                    </div>
                                </div>
                                <div className="column is-3">
                                    <label className="label">Estados</label>
                                    <div className="control">
                                        <Select
                                            isMulti
                                            placeholder={"seleccionar..."}
                                            name={"selectedOption"}
                                            value={this.state.selectedOption}
                                            onChange={this.changeSelectEstado}
                                            noOptionsMessage={() => "No hay opciones"}
                                            options={lodash.sortBy(this.state.optionState, [ts => ts.label.toLowerCase()], ['asc'])}
                                        />
                                    </div>
                            </div>
                            <div className="column is-3">
                                    <label className="label">Ciudades</label>
                                    <div className="control">
                                        <Select
                                            isMulti
                                            placeholder={"seleccionar..."}
                                            name={"selectedOptionC"}
                                            value={this.state.selectedOptionC}
                                            onChange={this.changeSelectCiudad}
                                            noOptionsMessage={() => "No hay opciones"}
                                            options={lodash.sortBy(this.state.optionCity, [ts => ts.label.toLowerCase()], ['asc'])}
                                        />
                                    </div>
                            </div>
                            <div className="column is-3">
                                    <label className="label">Drives</label>
                                    <div className="control">
                                        <Select
                                            isMulti
                                            placeholder={"seleccionar..."}
                                            name={"selectedOptionD"}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={this.state.selectedOptionD}
                                            onChange={this.changeSelectDrive}
                                            noOptionsMessage={() => "No hay opciones"}
                                            options={lodash.sortBy(this.state.optionDrive, [ts => ts.label.toLowerCase()], ['asc'])}
                                        />
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    {/*Mostramos cuando el usuario ha seleccionado un checkbox*/}
                {
                    this.state.updateMasive &&
                    <div className="card">
                        <div className="card-content">
                            <div className="columns is-vcentered">
                                <div className="column is-6">
                                    <Field label="Excluir toppings de los drives seleccionados" />
                                    <div className="has-text-center buttons-modal">
                                                <button
                                                    className= "button is-danger"
                                                    onClick={() => this.setState({confirmModalUpdateExcludeToppings:true})}>
                                                    Excluir toppings
                                                </button>
                                            </div>        
                                </div>
                                    {this.state.esAdmin && <>
                                        <div className="column is-4">
                                            <Field label="Precio">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    step="0.01"
                                                    className="input"
                                                    label="Precio"
                                                    maxLength="8"
                                                    name="PriceMasive"
                                                    value={this.state.priceMassive}
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                            </Field>
                                        </div>
                                        <div className="column is-2">
                                            <div className="has-text-center buttons-modal">
                                                <button
                                                    className="button is-success"
                                                    onClick={() => this.setState({ confirmModalUpdatePrice: true })}>
                                                    Aplicar
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    }
                            </div>
                        </div>

                    </div>
                }
                <br/>


                <div className="card">
                    <div className="card-content">
                        {this.state.inventoryItems.length !== 0
                        ?
                        <div>

                            <div className="columns">
                                <div className="column" style={{display: "flex"}}>
                                    <a className="button is-link" onClick={() => this.selectAllCheckBox()}>
                                        <span className="icon is-small">
                                            <i className={!this.state.buttonSelectAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                        </span>
                                        <span>{!this.state.buttonSelectAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                    </a>
                                </div>
                            </div>

                            <TableTemplate
                                columns={["InventoryItemId", "CodeProvider", "Name", "ShortName", "NombreListaPrecio", "Cantidad", "UnitSaleStr", "PrecioVenta"]}
                                columnsNames={["ID", "Código PT", "Nombre", "Nombre Corto", "Lista de Precio", "Cantidad", "Unidad", "PrecioVenta"]}
                                data={this.state.pageInventoryItems}
                                rowClickable={true}
                                details={(inventoryItem) => this.setState({modalUpdatePrice: true, inventoryItem: inventoryItem})}
                                canEdit 
                                canDelete={this.state.esAdmin ? true : false}
                                edit={(inventoryItem) => this.setState({modalUpdatePrice: true, inventoryItem: inventoryItem})}
                                remove={(inventoryItem) => this.setState({removeModal: true, inventoryItem: inventoryItem})}
                                checkbox = {true}
                                checkAll= {this.state.checkAll}
                                selectAllCheck={this.selectAllCheckBoxPage}
                                showView={this.showView}
                                extraButtons={(row) => {
                                    return (
                                        row.ToppingExcluido != null &&
                                        <div>
                                        <button className="button tableAction is-info tooltip" data-tooltip="Consultar Drives Excluidos"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.ShowModalToppingsExclude(row);
                                            // this.setState({
                                            //     menuRecetaModal: true,
                                            //     isLoading: true,
                                            //     menuRecetaModalBody: "Cargando menús que contienen '" + row.Description + "'...",
                                            //     menuRecetaModalTitle: "Menús que contienen '" + row.Description + "'",
                                            // }, this.handleMenuRecetaModalBody(row.RecipeId))
                                        }}
                                        >
                                            <i className="fa fa-ban"></i>
                                        </button>
                                    </div>
                                    )
                                }}
                            />
                        </div>

                        : <NoItems itemName="artículos en inventario"/>}
                        <Pagination
                            items={this.state.inventoryItems}
                            onChangePage={
                                (inventoryItems) => {
                                    let checkAllTemp = true;
                                    let cheked = false;

                                    inventoryItems.map(item => {
                                        if(item.statusCheck){
                                            cheked = true;
                                        } else {
                                            checkAllTemp = false;
                                        }
                                    })

                                    this.setState({ pageInventoryItems: inventoryItems, checkAll: checkAllTemp, updateMasive: this.state.selectedToppingsObjList.length > 0 })
                                }
                            }
                        />
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Artículo Inventario"}
                        cancel={() => this.setState({showModal: false, unit: null})}
                    >
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Artículo Inventario"}
                    modalBody={"ESTA ACCIÓN ELIMINARÁ TODAS LAS INSTANCIAS DEL TOPPING"}
                />}

                {this.state.confirmModalUpdatePrice && <AcceptModal
                    isActive={this.state.confirmModalUpdatePrice}
                    done={() => this.updateMasive()}
                    cancel={() => this.setState({confirmModalUpdatePrice: false})}
                    modalTitle={"Desea cambiar el precio a estos toppings?"}
                />}

                {this.state.confirmModalUpdateExcludeToppings && <AcceptModal
                    isActive={this.state.confirmModalUpdateExcludeToppings}
                    done={() => this.ExcludeSelectedToppings()}
                    cancel={() => this.setState({confirmModalUpdateExcludeToppings: false})}
                    modalTitle={"Desea excluir los toppings de los siguientes drives?"}
                />}

                {this.state.modalUpdatePrice &&
                    <AcceptModal
                    isActive={this.state.modalUpdatePrice}
                    done={() => this.updateIndividual()}
                    modalTitle={`Cambio de precio`}
                    cancel={() => this.setState({ modalUpdatePrice: false, inventoryItem: null, priceMassive:null})}
                    hideAccept={this.state.esAdmin ? false : true}
                    modalBody={
                        <div>
                            {this.state.esAdmin && <TextField
                            id="txtIndividualPrice"
                            autoFocus={true}
                            type="number"
                            min="0"
                            max="999"
                            maxLength="3"
                            required
                            label="Precio"
                            placeholder={this.state.inventoryItem.PrecioVenta}
                            value={this.state.priceMassive}
                            name="SalePrice"
                            onChange={(e) => this.handleChange(e)}
                            onKeyPress= {(event) => {
                                const keyCode = event.keyCode || event.which;
                                const keyValue = String.fromCharCode(keyCode);
                                if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                event.preventDefault();
                            }
                            }/>}
                            <Field label="Lista" isHorizontal>
                                <input
                                    className="input"
                                    type="text"
                                    value={this.state.inventoryItem.NombreListaPrecio}
                                    disabled={true}
                                />
                            </Field>
                            <Field label="Código" isHorizontal>
                                <input
                                    className="input"
                                    type="text"
                                    value={this.state.inventoryItem.CodeProvider}
                                    disabled={true}
                                />
                            </Field>
                            <Field label="Nombre" isHorizontal>
                                <input
                                    className="input"
                                    type="text"
                                    value={this.state.inventoryItem.Name}
                                    disabled={true}
                                />
                            </Field>
                        </div>

                    }
                    />
                }
                {this.state.modalSeeToppingsExclude &&
                    <AcceptModal
                    hideAccept={true}
                    isActive={this.state.modalSeeToppingsExclude}
                    modalTitle={`Drives excluidos para el topping ${this.state.LstDrivesExcluded.length > 0 ? this.state.LstDrivesExcluded[0].Topping : ''}`}
                    cancel={() => this.setState({ modalSeeToppingsExclude: false})}
                    modalBody={
                        <div>
                            <div className="column is-12" style={{textAlign: "right"}}>
                                    {this.state.esAdmin && <div className="has-text-center buttons-modal">
                                        <button
                                            className="button is-danger"
                                            onClick={() => {
                                                    this.RemoveAllExcludeToppings(this.state.LstDrivesExcluded)
                                                }
                                            }
                                            >
                                            Eliminar todos
                                        </button>
                                    </div>}
                                </div>
                            <TableTemplate
                                columns={["Drive"]}
                                columnsNames={["Drive"]}
                                data={this.state.LstDrivesExcluded}
                                canDelete
                                remove={(inventoryItem) => {
                                     let lstD =[]
                                     lstD.push(inventoryItem)
                                     this.RemoveSingleExcludeToppings(lstD)
                                    }
                                }
                            />
                        </div>
                    }
                    />
                }

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />

            </div>
        );
    }
}

export default Toppings;
