import React from 'react';
import './MenuInterior.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-caffenio-nuevo.png';
import HasAccess from '../util/hasAccess/HasAccess';
import roles from '../../util/strings/roles';

/**
 * Elementos del menú de la barra lateral izquierda 
 */
export default class MenuInterior extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null
    }
  }
  // Preparando para un menú más grande
  // También se podría utilizar la navbar para eso, se verá cuando el proyecto crezca.

  render() {
    return (
      <div className="has-text-left sidemenu padding-top">
        <figure className="image container-image">
          <img className="image-logo" alt="" src={logo} />
        </figure>
        <br />
        <ul className="menu-list">
          <Link to={'/'}><li>Inicio</li></Link>
        </ul>
        {/* Administrar recetas */}
        <HasAccess roles={[roles.ADMIN, roles.MKT, roles.ESPECIALISTA_DE_PRODUCTO, roles.ESTRATEGIA_DIGITAL, roles.COORDINADOR_GESTION_OPERACIONES, roles.LIDER_DRIVE, roles.CONFIGURACION_VP, roles.COORD_OPER_DRIVE, roles.MKT_OS]} >
          <p className="menu-label"> Administrar Recetas </p>
          <ul className="menu-list">
            <HasAccess roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES, roles.LIDER_DRIVE]}>
              {/**Articulos config */}
              <li>
                <a onClick={() => this.setState({ selectedArticulos: this.state.selectedArticulos ? null : "articulos" })}>
                  <i className={`fa ${this.state.selectedArticulos === "articulos" ? "fa-chevron-up" : "fa-chevron-down"}`}></i>Artículos
                </a>
                {this.state.selectedArticulos === "articulos" && <ul className="menu-list">
                  <Link id="linkUnits" to={'/unidades'}><li>Unidades</li></Link>
                  <Link id="linkInventoryTypes" to={'/tipo-inventario'}><li>Tipo inventario</li></Link>
                  <Link id="linkInventoryItems" to={'/items-inventario'}><li>Artículo inventario</li></Link>
                  <Link id="linkRelAlm" to={'/almacen'}><li>Almacén</li></Link>
                </ul>}
              </li>

              {/**Receta config */}
              <li>
                <a onClick={() => this.setState({ selectedRecetas: this.state.selectedRecetas ? null : "recetas" })}>
                  <i className={`fa ${this.state.selectedRecetas === "recetas" ? "fa-chevron-up" : "fa-chevron-down"}`}></i>Recetas
                </a>
                {this.state.selectedRecetas === "recetas" && <ul className="menu-list">
                  <Link id="linkClassifications" to={'/clasificaciones'}><li>Familia</li></Link>
                  <Link id="linkTypeRecipe" to={'/tipos-receta'}><li>Tipo Receta</li></Link>
                  <Link id="linkFlavorTypes" to={'/sabores'}><li>Tipo Sabor</li></Link>
                  <Link id="linkGrainTypes" to={'/granos'}><li>Tipo Grano</li></Link>
                  <Link id="linkMilkType" to={'/leches'}><li>Tipo Leche</li></Link>
                  <Link id="linkPresentations" to={'/presentaciones'}><li>Presentación</li></Link>
                  <Link id="linkRecipes" to={'/recetas'}><li>Receta</li></Link>
                  <Link id="linkRecipesImages" to={'/recetas-imagenes'}><li>Receta Imágenes</li></Link>
                </ul>}
              </li>
            </HasAccess>

            {/**Drive config */}
            <HasAccess roles={[roles.ADMIN, roles.MKT, roles.ESTRATEGIA_DIGITAL, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORD_OPER_DRIVE, roles.MKT_OS]}>
              <li>
                <a onClick={() => this.setState({ selectedConf: this.state.selectedConf ? null : "configuracionDrive" })}>
                  <i className={`fa ${this.state.selectedConf === "configuracionDrive" ? "fa-chevron-up" : "fa-chevron-down"}`}></i>Menús y Precios
                </a>
                {this.state.selectedConf === "configuracionDrive" && <ul className="menu-list">
                  <HasAccess roles={[roles.ADMIN, roles.MKT, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.MKT_OS]}>
                    <Link to={'/lista-precios'}><li>Lista de precios</li></Link>
                  </HasAccess>
                  <HasAccess roles={[roles.ADMIN, roles.MKT, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORD_OPER_DRIVE]}>
                    <Link to={'/toppings'}><li>Toppings</li></Link>
                  </HasAccess>
                  <HasAccess roles={[roles.ADMIN, roles.MKT, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORD_OPER_DRIVE, roles.MKT_OS]}>
                    <Link to={'/menus'}><li>Menú</li></Link>
                  </HasAccess>

                  <HasAccess roles={[roles.ADMIN, roles.MKT, roles.ESPECIALISTA_DE_PRODUCTO, roles.LIDER_DRIVE]}>
                    <Link to={'/featured'}><li>Producto Destacado</li></Link>
                  </HasAccess>
                </ul>}
              </li>
            </HasAccess>
            <HasAccess roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.LIDER_DRIVE, roles.ENCARGADO_DE_TURNO, roles.CONFIGURACION_VP, roles.SUPERVISOR_VENTA_DRIVE, roles.ADMINDRIVE]}>
              <Link to={'/caffenio-drive'}><li>Caffenio Drive</li></Link>
            </HasAccess>
          </ul>
        </HasAccess>
        <HasAccess roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.LIDER_DRIVE, roles.ENCARGADO_DE_TURNO, roles.CONFIGURACION_VP, roles.SUPERVISOR_VENTA_DRIVE, roles.ADMINDRIVE]}>
          <Link to={'/caffenio-drive'}><li>Caffenio Drive</li></Link>
          <p className="menu-label">Proyección de productos</p>
          <ul className="menu-list">
            <Link to={'/productos-proyeccion'}>  <li>Productos</li>  </Link>
            <Link to={'/reporte-proyeccion'}>  <li>Reporte proyecciones</li>  </Link>
          </ul>
          <p className="menu-label">Comunicados en VP</p>
          <ul className="menu-list">
            <Link to={'/comunicados-vp'}>  <li>Comunicados</li>  </Link>
          </ul>
        </HasAccess>

        {/* Inventarios */}
        <HasAccess roles={[roles.ADMIN, roles.COORDINADOR_DISTRIBUCION, roles.SUPERVISOR_VENTA_DRIVE]} >
          <p className="menu-label">
            INVENTARIOS
          </p>
          <ul className="menu-list">
            <Link to={'/remesas'}><li>Remesas</li></Link>
          </ul>
        </HasAccess>

        {/* CAFFENIO APP */}
        <HasAccess roles={[roles.ADMIN, roles.ESTRATEGIA_DIGITAL, roles.CRM, roles.ADMIN_APP, roles.MKT_OS]}>
          <p className="menu-label">Caffenio App</p>
          <ul className="menu-list">
            <HasAccess roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]}>
              <Link to={'/app/notificaciones/agenda'}><li>Agenda</li></Link>
              <Link to={'/app/notificaciones'}><li>Notificaciones</li></Link>
            </HasAccess>
            <HasAccess roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM]}>
              <Link to={'/app/segmentaciones'}><li>Segmentaciones</li></Link>
            </HasAccess>
            <HasAccess roles={[roles.ADMIN, roles.ESTRATEGIA_DIGITAL, roles.ADMIN_APP, roles.MKT_OS]}>
              <Link to={'/app/menu/orden'}><li>Orden de Menú</li></Link>
            </HasAccess>
          </ul>
        </HasAccess>
        {/* INTEGRACIONES */}
        <HasAccess roles={[roles.ADMIN, roles.ADMIN_UBER_EATS]}>
          <p className="menu-label">Integraciones</p>
          <ul className="menu-list">
            <Link to={'/restaurantes'}><li>Uber Eats</li></Link>
            {/*<Link to={'/ubereats/orden'}><li>Consulta de orden</li></Link>*/}
          </ul>
        </HasAccess>
        {/* SALDOS */}
        <HasAccess roles={[roles.ADMIN, roles.MKT]}>
          <p className="menu-label">
            Saldos
          </p>
          <ul className="menu-list">
            <Link to={'/saldo-regalo'}><li>Saldo Regalo</li></Link>
          </ul>
        </HasAccess>
        {/* Promociones QR */}
        <HasAccess roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]}>
          <p className="menu-label">
            Promociones
          </p>
          <ul className="menu-list">
            <Link to={'/app/promociones'}><li>Promociones QR</li></Link>
          </ul>
        </HasAccess>
        {/* Administración */}
        <HasAccess roles={[roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.MKT_OS]}>
          <HasAccess roles={[roles.ADMIN, roles.ESPECIALISTA_DE_GESTION, roles.MKT_OS, roles.MKT_REGIONAL, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO]}>
            <p className="menu-label">
              Administración
            </p>
            <ul className="menu-list">
              <HasAccess roles={[roles.ADMIN, roles.ADMINDRIVE]}>
                <Link to={'/coordinadores-gerentes'}><li>Coordinadores y Gerentes</li></Link>
{/*                 <Link to={'/asignacion'}><li>Líderes y Supervisores</li></Link> */}
              </HasAccess>
              <HasAccess roles={[roles.ADMIN, roles.MKT_OS, roles.MKT_REGIONAL]}>
                <Link to={'/promos'}><li>Promociones</li></Link>
              </HasAccess>
              <HasAccess roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO]}>
                <Link to={'/historial'}><li>Historial de Movimientos</li></Link>
              </HasAccess>
              <HasAccess roles={[roles.ADMIN]}>
                <Link to={'/catalogo-sucursal'}><li>Catálogo Sucursales</li></Link>
                <Link to={'/catalogo-plaza'}><li>Catálogo Plazas</li></Link>
                <Link to={'/catalogo-impuesto'}><li>Catálogo Tasa Impuestos</li></Link>
                <Link to={'/admin-usuarios'}><li>Administración de Usuarios</li></Link>
              </HasAccess>
            </ul>
          </HasAccess>
        </HasAccess>

        {/* reportes */}
        <HasAccess roles={[roles.ADMIN, roles.REPORTES, roles.ESPECIALISTA_DE_PRODUCTO, roles.SOPORTE]}>
          <p className="menu-label">
            Reportes
          </p>
          <ul className="menu-list">
            <HasAccess roles={[roles.ADMIN, roles.REPORTES, roles.ESPECIALISTA_DE_PRODUCTO, roles.SOPORTE]}>
              <Link to={'/cierre-dia'}><li>Cierre Día</li></Link>
            </HasAccess>
            <HasAccess roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.SOPORTE]}>
              <Link to={'/movimientos-menu'}><li>Movimientos SAG</li></Link>
            </HasAccess>
            <HasAccess roles={[roles.ADMIN, roles.SOPORTE]}>
              <Link to={'/historial-version'}><li>Historial Versiones</li></Link>
            </HasAccess>
            <HasAccess roles={[roles.ADMIN, roles.REPORTES]}>
              <Link to={'/pagos-bancos'}><li>Pagos Bancos</li></Link>
              <Link to={'/depositos-bancarios'}><li>Depósitos bancarios</li></Link>
              <Link to={'/reportes-conciliaciones'}><li>Conciliaciones</li></Link>
              <Link to={'/reportes-arqueos'}><li>Arqueos</li></Link>
              <Link to={'/reportes-retiros'}><li>Retiros</li></Link>
              <Link to={'/reportes-cancelaciones'}><li>Cancelaciones</li></Link>
            </HasAccess>
          </ul>
        </HasAccess>

        {/* CAFFENIO Empresas */}
        <HasAccess roles={[roles.ADMIN, roles.CRM_EMPRESARIAL]}>
          <p className="menu-label">
            Mi CAFFENIO Empresas
          </p>
          <ul className="menu-list">
            <Link to={'/micaffenio/participantes'}><li>Participantes</li></Link>
            <Link to={'/micaffenio/empresas'}><li>Empresas</li></Link>
          </ul>
        </HasAccess>


        <hr className="navbar-divider"></hr>
        <ul className="menu-list">
          <li><Link to={'/logout'}><i className="fa fa-sign-out"></i>Cerrar Sesión</Link></li>
        </ul>
      </div>
    )
  }
}