import React, { useState, useEffect } from 'react'
import Modal from '../util/modal/Modal'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import Field from '../util/field/Field';
import AdministrarEmpleadosAPI from '../../services/AdministrarEmpleados';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import ErrorMessages from '../../util/validation-error-messages';
import CaffenioDriveAPI from '../../services/caffenioDrive';
import { toast } from 'react-toastify';

// Tipos de empleado existentes
const COORDINADOR = 1
const SUPERVISOR = 2

export const EditCoordinadoresSupervisores = ({ empleadoSeleccionado, onClose,
    handleOpenLoading, handleCloseLoading, handleActualizarEmpleado }) => {

    const [state, setState] = useState({
        empleado: { ...empleadoSeleccionado, drivesAsignados: [] },
        listaGeneros: [],
        listaSegmentos: [],
        listaTiposEmpleado: [],
        listaDrives: [],
        listaDrivesAnterior: [],
        listaDrivesFiltrados: []
    });

    useEffect(() => {
        handleOpenLoading()
        let stateAux = { ...state }
        let listaGenerosAux = []
        let listaSegmentosAux = []
        let listaTiposEmpleadoAux = []
        let listaDrivesAux = []
        let drivesAsignadosAux = []
        AdministrarEmpleadosAPI.GetGeneros()
            .then(res => {
                listaGenerosAux = res.data
                listaGenerosAux.forEach(genero => {
                    if (genero.IdGender === stateAux.empleado.idSexo) {
                        stateAux.empleado.idSexo = genero
                    }
                });
                return AdministrarEmpleadosAPI.GetSegmentaciones()
            })
            .then(res => {
                listaSegmentosAux = res.data.filter(x => x.SegmentId !== 2)
                listaSegmentosAux.forEach(segmento => {
                    if (segmento.SegmentId === state.empleado.idSegmento) {
                        stateAux.empleado.idSegmento = segmento
                    }
                });
                return AdministrarEmpleadosAPI.GetTiposEmpleado()
            })
            .then(res => {
                listaTiposEmpleadoAux = res.data
                listaTiposEmpleadoAux.forEach(tipo => {
                    if (tipo.idTipoEmpleado === state.empleado.idTipoEmpleado) {
                        stateAux.empleado.idTipoEmpleado = tipo
                    }
                })
                return CaffenioDriveAPI.getCaffenios()
            })
            .then(res => {
                listaDrivesAux = res.data.filter(x => x.Active === true)
                listaDrivesAux = listaDrivesAux.map(x => {
                    if(x.idSegmento === 2){
                        x.idSegmento = 1
                    }
                    return x
                })
                return AdministrarEmpleadosAPI.GetSupervisoresDrivePorId(stateAux.empleado.idEmpleado)
            })
            .then(res => {
                drivesAsignadosAux = res.data
                for (let i = 0; i < drivesAsignadosAux.length; i++) {
                    listaDrivesAux.forEach(drive => {
                        if (drivesAsignadosAux[i].idCaffenio === drive.idCaffenio) {
                            drivesAsignadosAux[i] = drive
                        }
                    });
                }
                stateAux.listaGeneros = listaGenerosAux
                stateAux.listaSegmentos = listaSegmentosAux
                stateAux.listaTiposEmpleado = listaTiposEmpleadoAux
                stateAux.listaDrivesAnterior = drivesAsignadosAux
                stateAux.listaDrives = listaDrivesAux
                stateAux.listaDrivesFiltrados = listaDrivesAux.filter(x => x.idSegmento === stateAux.empleado.idSegmento.SegmentId)
                stateAux.empleado.drivesAsignados = drivesAsignadosAux
                setState(stateAux)
                handleCloseLoading()
            })
    }, [])

    const handleEditEmpleado = (empleado) => {
        if (state.listaDrivesAnterior === empleado.drivesAsignados) {
            empleado.drivesAsignados = []
        }
        let empleadoAux = {...empleado}
        empleadoAux.idSegmento = empleado.idSegmento.SegmentId
        empleadoAux.idSexo = empleado.idSexo.IdGender
        empleadoAux.idTipoEmpleado = empleado.idTipoEmpleado.idTipoEmpleado
        AdministrarEmpleadosAPI.PutEmpleado(empleadoAux)
            .then(x => {
                handleActualizarEmpleado(empleadoAux)
                toast.success("Empleado editado correctamente")
                onClose()
            })
            .catch(err => {
                toast.error(err.response.data.Message)
            })
    }

    const VALIDATION_SCHEMA = Yup.object({
        /* NumEmpleado: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(1, "Número de empleado debe tener 1 caracteres.")
            .max(6, "Ha excedido el límite de 6 caracteres.")
            .required("Campo número de empleado es requerido."), */
        Nombre: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(100, "Ha excedido el límite de 100 caracteres.")
            .required("Campo nombre es requerido."),
        /* ApellidoP: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre Corto debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo apellido paterno es requerido."),
        ApellidoM: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre Corto debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo apellido materno es requerido."), */
        CorreoElectronico: Yup.string()
            .trim()
            .email("El correo electrónico no cumple con el formato correcto")
            .max(60, "Ha excedido el límite de 60 caracteres.")
            .required("Campo correo electrónico es requerido.")
            .nullable(),
        idSexo: Yup.string().required("Campo género es requerido.").nullable(),
        idSegmento: Yup.string().required("Campo segmento es requerido.").nullable(),
        idTipoEmpleado: Yup.string().required("Campo tipo empleado es requerido.").nullable(),
    });

    return (
        <Modal
            showModal={true}
            modalTitle={"Editar Empleado"}
            width='60%'
            cancel={() => onClose()}
        >
            <Formik
                initialValues={state.empleado}
                validationSchema={VALIDATION_SCHEMA}
                onSubmit={values => {
                    if (values.idTipoEmpleado.idTipoEmpleado === SUPERVISOR
                        && values.drivesAsignados.length === 0) {
                        toast.error("Debe asignar por lo menos un drive")
                    } else {
                        handleEditEmpleado(values)
                    }
                }}
            >
                {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <h2>Información del empleado</h2>
                        <br />
                        <div>
                            <Field
                                label="Número de empleado"
                                hasError={!!errors.NumEmpleado || !!touched.NumEmpleado}
                                msgError={errors.NumEmpleado || touched.NumEmpleado ? errors.NumEmpleado : ""}
                                isHorizontal
                            >
                                <input
                                    disabled={true}
                                    name={"NumEmpleado"}
                                    value={values.NumEmpleado}
                                    onChange={handleChange}
                                    className={`input`}
                                />
                            </Field>

                            <br />

                            <Field
                                label="Nombre"
                                hasError={!!errors.Nombre || !!touched.Nombre}
                                msgError={errors.Nombre || touched.Nombre ? errors.Nombre : ""}
                                isHorizontal
                            >
                                <input
                                    name={"Nombre"}
                                    value={values.Nombre}
                                    onChange={handleChange}
                                    className={`input ${errors.Nombre && touched.Nombre ? "is-danger" : ""}`}
                                    placeholder={"Ingrese nombre del empleado"}
                                />
                            </Field>

                            <br />

                            <Field
                                label="Apellido Paterno"
                                hasError={!!errors.ApellidoP || !!touched.ApellidoP}
                                msgError={errors.ApellidoP || touched.ApellidoP ? errors.ApellidoP : ""}
                                isHorizontal
                            >
                                <input
                                    name={"ApellidoP"}
                                    value={values.ApellidoP}
                                    onChange={handleChange}
                                    className={`input ${errors.ApellidoP && touched.ApellidoP ? "is-danger" : ""}`}
                                    placeholder={"Ingrese apellido paterno del empleado"}
                                />
                            </Field>

                            <br />

                            <Field
                                label="Apellido Materno"
                                hasError={!!errors.ApellidoM || !!touched.ApellidoM}
                                msgError={errors.ApellidoM || touched.ApellidoM ? errors.ApellidoM : ""}
                                isHorizontal
                            >
                                <input
                                    name={"ApellidoM"}
                                    value={values.ApellidoM}
                                    onChange={handleChange}
                                    className={`input ${errors.ApellidoM && touched.ApellidoM ? "is-danger" : ""}`}
                                    placeholder={"Ingrese apellido paterno del empleado"}
                                />
                            </Field>

                            <br />

                            <Field
                                label="Correo Electrónico"
                                hasError={!!errors.CorreoElectronico || !!touched.CorreoElectronico}
                                msgError={errors.CorreoElectronico || touched.CorreoElectronico ? errors.CorreoElectronico : ""}
                                isHorizontal
                            >
                                <input
                                    name={"CorreoElectronico"}
                                    value={values.CorreoElectronico || ""}
                                    onChange={handleChange}
                                    className={`input`}
                                    placeholder={"Ingrese correo electrónico del empleado"}
                                />
                            </Field>
                        </div>

                        <br />

                        <Field
                            label="Género"
                            hasError={!!errors.idSexo || !!touched.idSexo}
                            msgError={errors.idSexo || touched.idSexo ? errors.idSexo : ""}
                            isHorizontal
                        >
                            <div className="control" style={{ width: "100%" }}>
                                <Select
                                    value={values.idSexo}
                                    placeholder={"Seleccionar..."}
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={val => {
                                        setFieldValue("idSexo", val)
                                    }}
                                    options={state.listaGeneros}
                                    getOptionLabel={(genero) => genero.Name}
                                    getOptionValue={(genero) => genero.IdGender}
                                />
                            </div>
                        </Field>

                        <br />

                        <Field
                            label="Puesto"
                            hasError={!!errors.idTipoEmpleado || !!touched.idTipoEmpleado}
                            msgError={errors.idTipoEmpleado || touched.idTipoEmpleado ? errors.idTipoEmpleado : ""}
                            isHorizontal
                        >
                            <div className="control" style={{ width: "100%" }}>
                                <Select
                                    isDisabled
                                    value={values.idTipoEmpleado}
                                    placeholder={"Seleccionar..."}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={state.listaTiposEmpleado}
                                    getOptionLabel={(tipoEmpleado) => tipoEmpleado.Descripcion}
                                    getOptionValue={(tipoEmpleado) => tipoEmpleado.idTipoEmpleado}
                                />
                            </div>
                        </Field>

                        {values.idTipoEmpleado.idTipoEmpleado &&
                            <div>
                                <hr />
                                <h2>Configuración de empleado</h2>
                                <br />
                                <Field
                                    label="Segmentación"
                                    hasError={!!errors.idSegmento || !!touched.idSegmento}
                                    msgError={errors.idSegmento || touched.idSegmento ? errors.idSegmento : ""}
                                    isHorizontal
                                >
                                    <div className="control" style={{ width: "100%" }}>
                                        <Select
                                            menuPlacement="auto"
                                            value={values.idSegmento}
                                            placeholder={"Seleccionar..."}
                                            noOptionsMessage={() => "No hay opciones"}
                                            onChange={val => {
                                                setFieldValue("idSegmento", val)
                                                setFieldValue("drivesAsignados", [])
                                                let filtrarDrives = state.listaDrives.map(x => {
                                                    if(x.idSegmento === 2 && val.SegmentId === 1){
                                                        x.idSegmento = 1
                                                    }
                                                    return x
                                                })
                                                filtrarDrives = filtrarDrives.filter(x => x.idSegmento === val.SegmentId)
                                                setState({ ...state, listaDrivesFiltrados: filtrarDrives })
                                            }}
                                            options={state.listaSegmentos}
                                            getOptionLabel={(segmento) => {
                                                switch (segmento.Name) {
                                                    case "Andatti drive":
                                                        return "Andatti"
                                                    case "Drive":
                                                        return "Caffenio"
                                                    case "momento espresso":
                                                        return "Momento Espresso"
                                                    default:
                                                        return segmento.Name
                                                }
                                            }}
                                            getOptionValue={(objSegment) => objSegment.SegmentId}
                                        />
                                    </div>
                                </Field>
                            </div>
                        }

                        {values.idTipoEmpleado.idTipoEmpleado === SUPERVISOR &&
                            <div>
                                <br />
                                <Field
                                    label="Drives asignados"
                                    isHorizontal
                                >
                                    <div className="control" style={{ width: "100%" }}>
                                        <Select
                                            menuPlacement="auto"
                                            isMulti
                                            value={values.drivesAsignados}
                                            placeholder={"Seleccionar..."}
                                            noOptionsMessage={() => "No hay opciones"}
                                            options={state.listaDrivesFiltrados}
                                            onChange={val => {
                                                setFieldValue("drivesAsignados", val)
                                            }}
                                            getOptionLabel={(drive) => drive.Name}
                                            getOptionValue={(drive) => drive.idCaffenio}
                                        />
                                    </div>
                                </Field>
                            </div>
                        }

                        <div className="has-text-right buttons-modal">
                            <div className="column">
                                <button type="submit" className="button is-success">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    )
}
