import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import Modal from '../../util/modal/Modal';
import InventoryItemForm from './InventoryItemForm';
import InventoryItemImageForm from './InventoryItemImageForm';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import Pagination from '../../util/pagination/Pagination';
import Loading from '../../util/loading/Loading';
import InventoryItemsAPI from '../../../services/InventoryItem';
import UnitsAPI from '../../../services/Units';
import SatAPI from '../../../services/Sat';
import { toast } from 'react-toastify';

import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../../util/validation-error-messages';
import lodash from 'lodash';
import roles from '../../../util/strings/roles';
import SyncInventoryItem from './SyncInventoryItem';

class InventoryItems extends Component {
    inventoryItems = [];

    constructor(props){
        super(props);
        this.state = {
            inventoryItems: [],
            pageInventoryItems: [],
            filterText: "",
            inventoryItem: null,
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false,
            syncModal: false
        }
    }

    componentWillMount(){
        this.setState({isLoading: true});       
        
        InventoryItemsAPI.getAll()
        .then(response => {
            let tempInventoryItems = [];
            response.data.map(obj => {
                tempInventoryItems.push(obj)
            })
            this.setState({inventoryItems: tempInventoryItems, isLoading: false});
            this.inventoryItems = tempInventoryItems;
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let inventoryItems = this.inventoryItems.filter(
            inventoryItem => inventoryItem.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || inventoryItem.CodeProvider.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) 
        );
        let state = this.state;
        state.filterText = text;
        state.inventoryItems = inventoryItems;
        this.setState(state);
    }

    create = () => {
               
        this.props.history.push({
            pathname: '/items-inventario/nuevo', 
            state: { inventoryItems: this.inventoryItems }
        });
        
    }

    edit = (inventoryItem) => {
        this.props.history.push({
            pathname : `/items-inventario/${inventoryItem.InventoryItemId}/editar`,
            state : { inventoryItems: this.inventoryItems }
        })
    }

    remove = () => {
        this.setState({isLoading: true});
        InventoryItemsAPI.delete(this.state.inventoryItem.InventoryItemId).then(response => {
            let state = {...this.state};

            state.inventoryItems.splice(state.inventoryItems.findIndex(c => c.InventoryItemId === state.inventoryItem.InventoryItemId), 1);
            state.pageInventoryItems.splice(state.pageInventoryItems.findIndex(c => c.InventoryItemId === state.inventoryItem.InventoryItemId), 1);
            this.inventoryItems.splice(state.pageInventoryItems.findIndex(c => c.InventoryItemId === state.inventoryItem.InventoryItemId), 1)
            state.isLoading = false;
            state.removeModal = false;
            state.inventoryItem = null;

            this.setState(state, () => toast.success("Artículo Inventario eliminado"));
        }).catch(err => {
            this.setState({isLoading: false, inventoryItem: null, removeModal: false});
            if(err.response)
                toast.error(err.response.data.Message);
            else
                toast.error(ErrorMessages.SinConexion);

        });
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="items-inventario"
                    tipoModoGuiado="articulo"
                    anterior="tipo-inventario"
                    siguiente='almacen' 
                    mensaje="Información, agrupación y asignación del producto terminado (PT) o externo  (PE)" />

                <h1 className="title is-2">Artículo inventario</h1>
                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'por Nombre/Nombre Corto/Código'}
                                            placeholderText={"Ingrese Nombre o Código de artículo"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]).length > 0 &&
                                    <a tabIndex="0" className="button is-success" onClick={(e) => this.create()}>
                                        <span className="icon is-small">    
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Artículo Inventario</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                
                <div className="card">
                    <div className="card-content">
                        {this.state.inventoryItems.length !== 0
                            ? <TableTemplate
                                columns={["InventoryItemId", "Name", "ShortName", "CodeProvider"]}
                                columnsNames={["ID", "Nombre", "Nombre Corto", "Código"]}
                                data={this.state.pageInventoryItems}
                                rowClickable={true}
                                details={(inventoryItem) => this.edit(inventoryItem)}
                                canEdit canDelete canSync
                                sync={(inventoryItem) => this.setState({ syncModal: true, inventoryItem: inventoryItem })}
                                edit={(inventoryItem) => this.edit(inventoryItem)}
                                remove={(inventoryItem) => this.setState({ removeModal: true, inventoryItem: inventoryItem })}
                                extraButtons={(row) => {
                                    return (
                                        row.IsTopping
                                            ? <button className="button tableAction is-default tooltip" data-tooltip="Imagen Topping"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    InventoryItemsAPI.get(row.InventoryItemId).then(response => {
                                                        this.setState({
                                                            showModal: true,
                                                            inventoryItem: { ...response.data }
                                                        })

                                    })
                                    .catch(err=>{})
                                    
                                    
                                    // this.setState({
                                    //     menuRecetaModal: true,
                                    //     isLoading: true,
                                    //     menuRecetaModalBody: "Cargando menús que contienen '" + row.Description + "'...",
                                    //     menuRecetaModalTitle: "Menús que contienen '" + row.Description + "'",
                                    // }, this.handleMenuRecetaModalBody(row.RecipeId))
                                }}
                                >
                                    <i className="fa fa-image"></i>
                                </button>
                                : ""
                                )
                            }}
                            
                        /> 
                        : <NoItems itemName="artículos en inventario"/>}
                        <Pagination items={this.state.inventoryItems} onChangePage={(inventoryItems) => this.setState({ pageInventoryItems: inventoryItems })} />
                    </div>
                </div>

                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={`Editar Imagen ${this.state.inventoryItem.Name}`}
                        cancel={() => this.setState({showModal: false, inventoryItem: null})}
                    >
                        <InventoryItemImageForm
                            inventoryItem={this.state.inventoryItem}  
                            back ={()=>this.setState({isLoading: false, showModal:false,inventoryItem:null })}                          
                            save = {(values) => {
                                this.setState({isLoading:true})
                                InventoryItemsAPI.editImage(values)
                                .then(response => {
                                    toast.dismiss()
                                    toast.success("Imagen actualizada");
                                    window.location.reload(false);

                                })
                                .catch(err => {  
                                    toast.dismiss()                                 
                                  if(err.response)
                                      toast.error(err.response.data)
                                  else
                                       toast.error(ErrorMessages.SinConexion)
                                }).finally(() => {
                                    this.setState({isLoading: false, showModal:false,inventoryItem:null })

                                })

                            }} 
                                
                        />   
                    </Modal>
                }

                {/* {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Artículo Inventario"}
                        cancel={() => this.setState({showModal: false, unit: null})}
                    >
                        <InventoryItemForm
                            inventoryItem={this.state.inventoryItem}
                            inventoryItems={this.inventoryItems} 
                            save={this.edit}
                        />   
                    </Modal>
                } */}
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Artículo Inventario"}
                />}
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                


                {this.state.syncModal && 
                <Modal
                    showModal={this.state.syncModal}
                    modalTitle={"Sincronizar artículo inventario"}
                    cancel={() => this.setState({ syncModal: false })}
                    width="80%"
                >
                    <SyncInventoryItem
                        inventoryItem={this.state.inventoryItem}
                        close={() => this.setState({ syncModal: false })}
                    />
                </Modal>
                }

            </div>
        );
    }
}

export default InventoryItems;
