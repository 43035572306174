import {apiUrl} from '../constants';
import axios from 'axios';
import { stringify } from 'querystring';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
});

export default class HistorialMovimientosAPI {

    /**
     * Lista el historial de movimientos.
     * @returns Promise
     */
    static getHistorialMovimientos(params) {
        return request.get(`/HistorialMovimientos?${stringify(params)}`);
    }

    /**
     * Lista de historial de versiones
     */
    static ObtenerHistorialVersiones(filtro){
        return request.post('SistemaVersion/Historial', filtro)
    }

    static ObtenerNumVersionSync(){
        return request.get('SistemaVersion/ObtenerNumVersionSync')
    }

    static ObtenerTipoSistemas(){
        return request.get('SistemaVersion/ObtenerTipoSistemas')
    }

    static ActualizarTipoSistema(tipoSistema){
        return request.put('SistemaVersion/ActualizarTipoSistema', tipoSistema)
    }
}